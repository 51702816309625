.topbar {
  &.mat-toolbar {
    position: relative;
    box-shadow: 0 10px 30px 0 rgba(47, 60, 74, .08);
    z-index: 1001;
  }

  .mat-select-value {
    font-weight: 400;
    color: #ffffff;
  }

  &.mod {
    padding-left: 15px;
    padding-right: 15px;
    background: #fafafa;

    .content {
      max-width: 1400px;
      width: 100%;
      margin: 0 auto;
      padding: 10px 0 0 0;
    }

    .login {
      width: fit-content;
      border-radius: 15px;
      padding: 13px 20px;
      background: #fff;
      box-shadow: 0 8px 12px rgb(47 60 74 / 4%), 0 2px 6px rgb(47 60 74 / 8%);
      margin: 0.75rem;

      .avatar {
        box-sizing: border-box;
        width: 42px;
        height: 42px;
        background: #EBEBEB;
        border: 1px solid #B5B5B5;
        border-radius: 50%;

        .icon-avatar {
          font-size: 24px
        }
      }

      .text {
        font-size: 14px;
        color: #312F32;
      }

      .icon {
        font-size: 14px;
        width: 14px;
        height: 14px;
        max-height: 14px;
        color: #312F32;
      }
    }

  }
}

.toggle-collapsed {
  transform: rotate(0deg);
  -webkit-transition: transform .3s cubic-bezier(.35, 0, .25, 1);
  transition: transform .3s cubic-bezier(.35, 0, .25, 1);
}

.sidebar-compact .toggle-collapsed {
  transform: rotate(180deg);
}

.search-bar {
  .top-search-form {
    position: relative;
    background: #fff;
    border-radius: 40px;
    margin-right: 1rem;
    display: block;
    max-width: 220px;
    margin-left: 20px;
    box-shadow: inset 0 0 2px 2px rgba(136, 136, 136, 0.2);

    .material-icons {
      position: absolute;
      top: 50%;
      left: 10px;
      margin-top: -12px;
      color: rgba(0, 0, 0, .87);
    }

    input {
      font-size: 1rem;
      padding: .6rem .75rem;
      z-index: 2;
      cursor: text;
      text-indent: 30px;
      border: none;
      background: transparent;
      width: 100%;
      outline: 0;
    }
  }

}

