$border-color: rgba(0, 0, 0, 0.1);

$utilities: (
  "shadow": null,
  "border-color": null,
  "order": null,
  "negative-margin": null,
  "negative-margin-x": null,
  "negative-margin-y": null,
  "negative-margin-top": null,
  "negative-margin-right": null,
  "negative-margin-bottom": null,
  "negative-margin-left": null,
  "color": null,
  "background-color": null,
  "gradient-color": null,
  "font-family": null,
  "border-light": (
    property: border,
    class: "border-light",
    values: (
      null: 1px solid rgba(255, 255, 255, 0.1)
    )
  ),
  "border-top-light": (
    property: border-top,
    class: "border-top-light",
    values: (
      null: 1px solid rgba(255, 255, 255, 0.1)
    )
  ),
  "border-right-light": (
    property: border-right,
    class: "border-right-light",
    values: (
      null: 1px solid rgba(255, 255, 255, 0.1)
    )
  ),
  "border-bottom-light": (
    property: border-bottom,
    class: "border-bottom-light",
    values: (
      null: 1px solid rgba(255, 255, 255, 0.1)
    )
  ),
  "border-left-light": (
    property: border-left,
    class: "border-left-light",
    values: (
      null: 1px solid rgba(255, 255, 255, 0.1)
    )
  ),
  "font-weight": (
    property: font-weight,
    values: (
      light: 300,
      lighter: lighter,
      normal: 400,
      semi: 500,
      bold: 600,
      bolder: bolder
    )
  ),
);

@import "avatar";
@import "height-width";
@import "icons";
@import "spacing";
@import "others";


@import "base/functions";
@import "base/variables";
@import "base/mixins";
@import "base/utilities";
@import "base/utilities/api";


table {
  width: 100%;

  tr.expand-detail-row {
    height: 0;
    overflow: hidden;
  }

  .expand-element-detail {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

}

p.notes {
  background: rgba(0, 0, 0, 0.02);
  margin: 0px;
  padding: 12px;
  display: block;
  position: relative;
  border-left: 3px solid rgba(0, 0, 0, 0.08);
  border-right: 3px solid rgba(0, 0, 0, 0.08);

  span {
    font-weight: bold;

    &.text {
      font-weight: normal;
    }
  }
}

.list-style {
  padding-left: 0;
  list-style: none;

  &.margin-none {
    margin: 0;
  }

  &.no-margin {
    margin: 0;
  }

  .list-line {
    padding: 12px 0;

    &.short {
      padding: 6px 0;
    }

    &.group {
      padding-bottom: 0;
    }

    &.first {
      padding: 0 0 12px;
    }

    &.last {
      padding: 12px 0 0;
    }
  }

  .list-title {
    font-size: 16px;
    font-weight: bold;
  }

  .list-subtitle {
    font-size: 14px;
    font-weight: bold;
  }

  .list-p {
    font-size: 14px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 0;
  }
}

.margin-bottom-none {
  margin-bottom: 0 !important;
}

.margin-none {
  margin: 0 !important;
}

.line-through {
  text-decoration: line-through;
}
