/*
 *
 *   EGRET - Angular Material Design Admin Template
 *
 *
*/

// // Third pirty style files
// // Sidebar Scroll
// //  star-rating styles (Used in Shop app)
// // Data Table
// // Rich Text Editor
// // Drag and Drop
// // Calendar
// // User tour
// @import 'hopscotch/dist/css/hopscotch.min.css';
// // Page loader Topbar
// @import '../../vendor/pace/pace-theme-min.css';

// Main Variables File
@import "scss/variables";

@import "scss/mixins/mixins";
@import "scss/colors";

@import "scss/animations";

// Open _main.scss and keep files which are required for your current layout.
@import "scss/main/main";

// Angular material theme files
@import "scss/themes/init";

@import "scss/utilities/utilities";
@import "scss/components/components";
@import "scss/palette";

@import "scss/page-layouts";

// Open _views.scss and remove unnecessary view related files
@import "scss/views/views";


table {
  width: 100%;
}


.circle-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
}


html {
  table {
    td {
      .mat-mdc-form-field {
        margin-top: 4px;
        height: 56px;
      }
    }
  }
  .mdc-drawer-content {
    scroll-behavior: smooth;
  }

  .mdc-checkbox {
    display: block;

    .mdc-checkbox-layout {
      white-space: normal;
    }
  }

  mat-radio-group {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
}

mat-radio-button {
  .mdc-radio-label {
    white-space: normal !important;
  }
}


mat-radio-button + mat-radio-button {
  margin-top: 12px;
  margin-left: 0;

}

.badge-img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 2px solid var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-page-canvas {
  display: block;
  max-width: 90%;
  min-width: 512px;
  width: 100%;
  margin: 5px auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
