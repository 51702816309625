// Remove imports which are not needed for your project
@import "sessions/sessions";
@import "inbox";
@import "calendar";
@import "chats";
@import "gallery";
@import "pricings";
@import "users";
@import "profile";
@import "shop";
@import "home";
@import "invoice";
@import "todo/todo-details";
@import "todo/todo-list";
@import "todo/todo";


/* 
  Only Required if you want to use Angular Landing
  (https://themeforest.net/item/angular-landing-material-design-angular-app-landing-page/21198258)
*/
// @import "landing";
